/* stylelint-disable selector-pseudo-class-no-unknown */

.container :global(.main-picture) {
  display: none;
  background: var(--bg-card);
}

.compositeContainer {
  max-width: var(--desktop-max-width);
  z-index: 1;
  position: relative;
}

.reduced {
  overflow: hidden;
  height: 50vh;
}

.showMoreLink {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 50%, rgba(0 0 0 / 100%) 100%);
  text-align: center;
  color: var(--text-color-alt);
  line-height: 2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition-default);
}

.showMoreLink:hover {
  color: var(--color-primary);
  transition: var(--transition-default);
}

.showMoreLink path {
  fill: var(--text-color-alt);
}

.showMoreLink svg {
  margin: 0 0.5rem;
}

@media (width >= 768px) {
  .container {
    float: right;
    width: var(--identity-container-width);
    margin: var(--horizontal-spacing-default);
    transition: width var(--transition-default);
    position: absolute;
    top: 11.9rem;
    z-index: 1;
    right: var(--horizontal-spacing-default);
  }

  .container :global(.main-picture) {
    display: block;
    right: 0;
    bottom: calc(var(--horizontal-spacing-default) * -1);
    width: var(--identity-container-width);
    margin-right: var(--horizontal-spacing-default);
    z-index: 2;
    border: 1px solid var(--bg-card-title);
    border-bottom: none;
    transition: width var(--transition-default);
  }

  .compositeContainer {
    position: initial;
  }
}

@media (width >= 1200px) {
  .container {
    right: calc(calc(100vw - var(--desktop-max-width)) / 2);
  }
}
