.title {
  color: var(--color-primary);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7rem;
  margin-bottom: 0.3rem;
}

.items {
  margin: 0;
  padding: 0;
  list-style: none;
  color: var(--text-color-alt);
  font-size: 0.9rem;
}
