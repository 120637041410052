.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 2rem 0 2.5rem;
}

.logo {
  width: 20rem;
  margin-bottom: 1rem;
  max-width: 60vw;
}

.logo img {
  width: inherit !important;
}
