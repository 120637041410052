.container {
  background: var(--bg-color);
  padding: var(--horizontal-spacing-default);
}

.loader {
  text-align: center;
  padding: 2rem 0;
}

.loader svg {
  max-width: 5rem;
  max-height: 5rem;
}

.loader path {
  fill: #ccc;
}

.preview {
  display: flex;
  flex-direction: column;
}

.head {
  display: flex;
  flex-direction: row;
}

.titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9rem;
}

.title {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
}

.picture {
  width: 4rem;
  height: 4rem;
  margin-right: var(--horizontal-spacing-default);
}

.description {
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.links {
  display: flex;
  margin: 0.2rem 0 0.5rem;
}

.links a {
  width: 50%;
  flex-shrink: 0;
  color: var(--color-primary-attenuate);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}

.links svg {
  margin-right: var(--horizontal-spacing-default);
}

.links path {
  fill: var(--color-primary-attenuate);
}
