.item {
  display: flex;
  position: relative;
  padding: var(--horizontal-spacing-default) 0;
}

.content {
  position: absolute;
  left: calc(calc(var(--chronology-size) / 2) + calc(var(--horizontal-spacing-default) * 1.5));
  top: 50%;
  font-weight: 500;
  color: var(--color-primary-attenuate);
}

.timeline {
  width: var(--chronology-size);
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  padding: 0 var(--horizontal-spacing-default);
  display: flex;
  justify-content: center;
}

.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.6rem;
  height: 0.6rem;
  background-color: var(--color-primary-attenuate);
  border: 1px solid var(--color-primary-attenuate);
  border-radius: 500px;
  margin-top: calc(var(--horizontal-spacing-default) * 2);
  z-index: 2;
}
