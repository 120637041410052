.container {
  margin: 0 auto;
  display: grid;
  gap: calc(var(--horizontal-spacing-default) * 2) var(--horizontal-spacing-default);
}

@media (width >= 320px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 525px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 700px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }

  .container.large {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 875px) {
  .container {
    grid-template-columns: repeat(5, 1fr);
  }

  .container.large {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width >= 1050px) {
  .container {
    grid-template-columns: repeat(6, 1fr);
  }

  .container.large {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width >= 1225px) {
  .container {
    grid-template-columns: repeat(6, 1fr);
  }

  .container.large {
    grid-template-columns: repeat(4, 1fr);
  }
}

.container img,
.container svg {
  width: 100%;
}

.container svg {
  height: 100%;
}
