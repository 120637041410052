.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 33rem;
}

.title {
  font-size: 1.1rem;
  color: var(--text-color-alt);
  text-shadow: 0 0 5px #000;
  font-weight: 500;
  padding: var(--horizontal-spacing-default);
  background: linear-gradient(240deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 50%) 150%);
}

.entries {
  padding: var(--horizontal-spacing-default);
}

.button {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(var(--horizontal-spacing-default) * 2);
}

.logo {
  margin: auto;
  margin-top: 20rem;
  width: 70vw;
  margin-bottom: calc(var(--horizontal-spacing-default) * 3);
  max-width: 20rem;
}
