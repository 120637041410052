.container {
  max-width: 100vw;
  aspect-ratio: 4/3;
}

@media (width >= 768px) {
  .container {
    aspect-ratio: 8/3;

    /* To Remove, used to clean cache */
    background: transparent;
  }
}
