.container {
  padding: var(--horizontal-spacing-default);
}

.loader {
  margin: calc(var(--horizontal-spacing-default) * 2) 0;
  text-align: center;
}

.loader svg {
  width: 5rem;
  height: 5rem;
}

.loader path {
  fill: #aaa;
}
