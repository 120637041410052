.content {
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  color: #aaa;
}

.source a {
  text-decoration: underline;
}

.source a:hover,
.source a:active {
  color: #eee;
}

.source svg {
  width: 0.7rem;
  margin-right: 0.3rem;
}

.source path {
  fill: #aaa;
}
