.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (width >= 768px) {
  .container {
    flex-direction: row;
  }
}

.noContent {
  text-align: center;
  color: #888;
  margin: 3rem auto 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noContent p {
  margin: 0;
}

.noContent svg {
  width: 15rem;
  height: 10rem;
}

.noContent g {
  fill: #888;
}

.aside {
  width: var(--identity-container-width);
  flex-shrink: 0;
  margin-left: var(--horizontal-spacing-default);
}
