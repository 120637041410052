.container {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

.line {
  position: absolute;
  background-color: var(--color-primary-attenuate);
  width: 1px;
  height: 100%;
  left: calc(var(--chronology-size) / 2);
}

@media (width >= 768px) {
  .container {
    max-width: 40rem;
    margin: auto;
  }
}
