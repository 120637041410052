.section:first-of-type {
  border-top: 2px solid #555;
}

.container {
  display: flex;
  flex-wrap: wrap;
  background: var(--bg-card);
}

.container > * {
  width: 50%;
  flex-grow: 1;
}

.title {
  background: var(--bg-card-title);
  text-transform: uppercase;
  color: var(--text-color-alt);
  padding: var(--horizontal-spacing-default);
  font-weight: 500;
  border-bottom: 1px solid #323232;
  font-size: 0.8rem;
}

.showMoreLink {
  background: var(--bg-card);
  color: var(--text-color-alt);
  padding: var(--horizontal-spacing-default);
  border-bottom: 1px solid #323232;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.showMoreLink svg {
  margin-right: 0.2rem;
}

.showMoreLink path {
  fill: var(--text-color-alt);
}
