.container {
  display: flex;
  margin-bottom: var(--horizontal-spacing-default);
}

.container > *:nth-child(2n) {
  display: none;
}

@media (width >= 768px) {
  .container > * {
    width: 50%;
    margin-right: var(--horizontal-spacing-default);
  }

  .container > *:nth-child(2n) {
    display: block;
  }
}
