.hidden {
  display: none;
}

.closed {
  transform-origin: 12px 12px;
  transition: transform 200ms ease-in;
}

.opened {
  transform: rotate(180deg);
  transform-origin: 12px 12px;
  transition: transform 200ms ease-in;
}

.paragraphHidden {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
}

p.paragraphVisible {
  height: initial;
  overflow: initial;
  margin: 1rem 0;
}

.title {
  margin: calc(var(--horizontal-spacing-default) * 2) 0 -0.5rem 0;
  line-height: 1.5rem;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
}

.titleContent {
  flex-grow: 1;
}

.subtitle {
  margin: var(--horizontal-spacing-default) 0 -0.5rem 0;
  line-height: 1.5rem;
  font-weight: 500;
  font-size: 1rem;
}

.image {
  display: inline-block;
  margin: -1rem 0;
}

.image img {
  width: 100%;
}

.blockquote {
  font-size: 1.1rem;
  line-height: 1.8rem;
  width: 80%;
  margin: calc(var(--horizontal-spacing-default) * 3) auto;
  position: relative;
}

@media (width >= 768px) {
  .blockquote {
    width: 90%;
  }
}

.blockquote h1 {
  font-size: 4rem;
}

.blockquote p {
  font-style: italic;
  margin-bottom: 0;
  margin-top: 0;
}

.blockquote p:first-of-type::before,
.blockquote p:last-of-type::after {
  content: '“';
  font-family: Georgia, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 4rem;
  margin: 0.5rem 0 0 -2.5rem;
  position: absolute;
  opacity: 0.5;
  color: var(--color-primary-attenuate);
}

.blockquote p:last-of-type::after {
  content: '”';
  margin: 1rem 0 0 0.5rem;
}

.blockquote cite {
  display: block;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  line-height: 1.2rem;
}
