.container {
  color: var(--text-color);
  min-width: 140px;
}

.containerAlt {
  color: var(--text-color-alt);
}

.fixedPictureSize {
  min-width: 140px;
  max-width: 140px;
}

.picture {
  display: flex;
  margin-bottom: 0.2rem;
  flex-direction: column-reverse;
}

.picture img,
.picture svg {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid var(--separator-color);
}

.picture svg {
  height: 100%;
}

.subtitle,
.title {
  line-height: 1.2rem;
}

.subtitle {
  font-size: 0.7rem;
  text-transform: uppercase;
}

.title {
  font-weight: 500;
}
