.content {
  font-size: 0.95rem;
  line-height: 1.5rem;
  margin: 0 var(--horizontal-spacing-default);
}

.content a {
  color: var(--color-primary-attenuate);
  text-decoration: none;
}

.content a:hover {
  text-decoration: underline;
}

.content a:active {
  color: var(--color-primary);
}
