.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1001; /* To pass over react menu burger icon */
  background: rgb(0 0 0 / 80%);
  transition: all 1s linear;
}
