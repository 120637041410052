.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--horizontal-spacing-default);
}

.references {
  margin: 0;
  margin-bottom: var(--horizontal-spacing-default);
  padding: 0;
  padding-left: 1rem;
}

.reference {
  line-height: 1.8rem;
}

.reference a {
  color: var(--color-primary-attenuate);
}

.reference a:hover {
  text-decoration: underline;
}

.referenceTypes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (width >= 768px) {
  .referenceType {
    width: 50%;
  }
}
