.container {
  width: 100%;
  height: 100%;
  color: var(--text-color-alt);
  padding: var(--horizontal-spacing-default);
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  flex-direction: column;
  min-width: 150px;
  min-height: 225px;
}

.subtitle,
.title {
  line-height: 1.2rem;
  text-shadow: 0 0 2px #000;
  max-width: 127px;
}

.subtitle {
  font-size: 0.7rem;
  text-transform: uppercase;
}

.title,
.count {
  font-weight: 500;
}
