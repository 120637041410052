.container {
  display: flex;
  padding: var(--horizontal-spacing-default);
  font-size: 0.9rem;
  backdrop-filter: blur(10px);
  color: var(--text-color-alt);
  height: 100%;
}

.content {
  flex-grow: 1;
}

.title {
  font-size: 1.2rem;
}

.subtitle {
  font-size: 0.8rem;
  text-transform: uppercase;
}

.description {
  color: #ccc;
}

.title,
.subtitle,
.description {
  text-shadow: 0 0 5px black;
}

.button div {
  box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
}

.image {
  width: 40vw;
  height: 40vw;
  max-width: 200px;
  max-height: 200px;
  margin-left: var(--horizontal-spacing-default);
  flex-shrink: 0;
}
