.description {
  line-height: 1.5rem;
  padding: var(--horizontal-spacing-default);
  white-space: pre-wrap;
}

.highlighted {
  font-weight: 500;
}

.noBottom {
  padding-bottom: 0;
}

.expandLink {
  color: var(--color-primary-attenuate);
  display: block;
  cursor: pointer;
}

.type {
  --space: 0.6rem;
  --color: var(--color-primary-attenuate);

  text-transform: uppercase;
  color: var(--color);
  border-right: 2px solid var(--color);
  padding-right: var(--space);
  margin-right: var(--space);
  line-height: 1rem;
}
