.container svg {
  width: 30%;
  height: inherit;
}

.link {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 1/1;
  transition: var(--transition-default);

  /* To Remove, used to clean cache */
  margin-right: 0;
}

.link:hover {
  color: var(--color-primary-attenuate);
  transition: var(--transition-default);
}
