.link {
  display: flex;
  min-height: 8rem;
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
}

.emblem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4.5rem;
  min-height: 4.5rem;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 500px;
  margin-top: calc(var(--horizontal-spacing-default) * 2);
}

.image .emblem {
  border: none;
  background: transparent;
}

.item:hover .title {
  color: var(--color-primary-attenuate);
  transition: var(--transition-default);
}

.item:hover .emblem {
  border-color: var(--color-primary-attenuate);
  transition: var(--transition-default);
}

.item:hover svg path {
  fill: var(--color-primary-attenuate);
  transition: var(--transition-default);
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: calc(var(--horizontal-spacing-default) * 2) var(--horizontal-spacing-default);
  gap: var(--horizontal-spacing-default);
}

.description {
  line-height: 1.5rem;
}

.timeline {
  width: var(--chronology-size);
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  padding: 0 var(--horizontal-spacing-default);
}

.emblem > svg {
  position: absolute;
  flex-grow: 0;
  width: 75%;
  height: 75%;
}

.image {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background-size: cover;
  background-position: center;
  margin-top: calc(var(--horizontal-spacing-default) * -1);
  margin-bottom: calc(var(--horizontal-spacing-default) * 2);

  /* To Remove, used to clean cache */
  margin-right: 0;
}

@media (width >= 768px) {
  .image {
    width: auto;
    margin-left: calc(var(--chronology-size) + var(--horizontal-spacing-default));
  }

  .image .line {
    display: none;
  }
}

.image > * {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.image .line {
  position: absolute;
  width: 1px;
  height: 100%;
  left: calc(var(--chronology-size) / 2);
  background-color: var(--color-primary-attenuate);
}
