.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

@media (width >= 768px) {
  .container {
    flex-direction: row;
  }
}

.aside {
  width: var(--identity-container-width);
  flex-shrink: 0;
  display: none;
}

@media (width >= 768px) {
  .aside {
    display: block;
  }
}

.content {
  max-width: 750px;
  flex-grow: 1;
}
