.container {
  list-style: none;
  padding: 0;
  display: flex;
  font-size: 0.9rem;
}

.container > * {
  padding: 0.8rem 0 0.8rem 0.8rem;
}

.container a {
  color: var(--color-primary-attenuate);
}

.container a::after {
  content: '›';
  padding-left: 0.5rem;
  color: #000;
}
