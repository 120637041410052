.coverContainer {
  max-width: 100vw;
  background: #222;
}

.container {
  --no-picture-cover-height: 15rem;

  height: var(--no-picture-cover-height);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  padding: 0.6rem;
  max-width: var(--desktop-max-width);
  margin: auto;
}

.coverContainer.noPicture {
  background: #222;
}

.coverContainer.noPicture .container {
  aspect-ratio: initial;
  height: 8rem;
}

.coverContainer.compacted {
  --no-picture-cover-height: 15rem;

  height: var(--no-picture-cover-height);
}

.coverContainer.compacted .container {
  --no-picture-cover-height: 15rem;

  aspect-ratio: initial;
  height: var(--no-picture-cover-height);
  transition: var(--transition-default);
}

.titles {
  color: var(--text-color-alt);
  text-shadow:
    0 0 5px black,
    0 0 10px black;
  font-weight: 500;
}

.title {
  font-size: 1.5rem;
  font-family: Oswald, 'Helvetica Neue', arial, sans-serif;
}

@media (width >= 768px) {
  .container {
    height: auto;
    aspect-ratio: 8/3;

    /* To Remove, used to clean cache */
    background: transparent;
  }

  .coverContainer.noPicture .container {
    height: 15rem;
  }

  .title {
    font-size: 2rem;
  }
}

.preTitle {
  text-transform: uppercase;
  line-height: 1.5rem;
}

.readTime {
  color: var(--text-color-alt);
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 0.1rem;
}

.readTime svg {
  margin-right: 0.3rem;
}
