.container {
  padding-bottom: var(--horizontal-spacing-default);
}

.withoutSwipper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.withoutSwipper > * {
  margin-right: var(--horizontal-spacing-default);
  margin-bottom: var(--horizontal-spacing-default);
}
