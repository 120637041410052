.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  /* Inside Auto Layout */
  align-self: stretch;
  flex-grow: 0;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
  justify-content: center;
}

.container.left {
  justify-content: left;
}
